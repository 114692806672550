"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.title = exports.secondary = exports.primary = exports.header = exports.editor = void 0;
var editor_1 = require("./editor");
Object.defineProperty(exports, "editor", { enumerable: true, get: function () { return editor_1.editor; } });
var header_1 = require("./header");
Object.defineProperty(exports, "header", { enumerable: true, get: function () { return header_1.header; } });
var primary_1 = require("./primary");
Object.defineProperty(exports, "primary", { enumerable: true, get: function () { return primary_1.primary; } });
var secondary_1 = require("./secondary");
Object.defineProperty(exports, "secondary", { enumerable: true, get: function () { return secondary_1.secondary; } });
var title_1 = require("./title");
Object.defineProperty(exports, "title", { enumerable: true, get: function () { return title_1.title; } });
