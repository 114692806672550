"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPACE = exports.ALT = exports.BACKTICK = exports.DOT = exports.ARROW_DOWN = exports.ARROW_RIGHT = exports.ARROW_UP = exports.ARROW_LEFT = exports.ESC = exports.ENTER = exports.TAB = exports.BACKSPACE = void 0;
exports.BACKSPACE = 8;
exports.TAB = 9;
exports.ENTER = 13;
exports.ESC = 27;
exports.ARROW_LEFT = 37;
exports.ARROW_UP = 38;
exports.ARROW_RIGHT = 39;
exports.ARROW_DOWN = 40;
exports.DOT = 190;
exports.BACKTICK = 192;
exports.ALT = 18;
exports.SPACE = 32;
