"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasLogIn = void 0;
function hasLogIn() {
    const hasDevAuth = process.env.LOCAL_SERVER || process.env.STAGING;
    return Boolean(typeof document !== 'undefined' &&
        (document.cookie.indexOf('signedIn') > -1 ||
            (hasDevAuth && document.cookie.indexOf('signedInDev') > -1)));
}
exports.hasLogIn = hasLogIn;
