"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    $schema: 'vscode://schemas/color-theme',
    type: 'light',
    colors: {
        'activityBarBadge.background': '#007acc',
        'editor.background': '#ffffff',
        'editor.foreground': '#000000',
        'editor.inactiveSelectionBackground': '#e5ebf1',
        'editor.selectionHighlightBackground': '#add6ff4d',
        'editorIndentGuide.activeBackground': '#939393',
        'editorIndentGuide.background': '#d3d3d3',
        'editorSuggestWidget.background': '#f3f3f3',
        'input.placeholderForeground': '#adadad',
        'list.hoverBackground': '#e8e8e8',
        'sideBarTitle.foreground': '#6f6f6f',
        'activityBar.background': '#2c2c2c',
        'activityBar.dropBackground': '#ffffff1f',
        'activityBar.foreground': '#ffffff',
        'activityBarBadge.foreground': '#ffffff',
        'badge.background': '#bebebe',
        'badge.foreground': '#ffffff',
        'button.background': '#007acc',
        'button.foreground': '#ffffff',
        'button.hoverBackground': '#0062a3',
        'debugExceptionWidget.background': '#f1dfde',
        'debugExceptionWidget.border': '#a31515',
        'debugToolBar.background': '#f3f3f3',
        descriptionForeground: '#6c6c6cb3',
        'diffEditor.insertedTextBackground': '#9bb95533',
        'diffEditor.removedTextBackground': '#ff000033',
        'dropdown.background': '#ffffff',
        'dropdown.border': '#cecece',
        'editor.findMatchBackground': '#a8ac94',
        'editor.findMatchHighlightBackground': '#ea5c0055',
        'editor.findRangeHighlightBackground': '#b4b4b44d',
        'editor.hoverHighlightBackground': '#add6ff26',
        'editor.lineHighlightBorder': '#eeeeee',
        'editor.rangeHighlightBackground': '#fdff0033',
        'editor.selectionBackground': '#add6ff',
        'editor.wordHighlightBackground': '#57575740',
        'editor.wordHighlightStrongBackground': '#0e639c40',
        'editorActiveLineNumber.foreground': '#0b216f',
        'editorBracketMatch.background': '#0064001a',
        'editorBracketMatch.border': '#b9b9b9',
        'editorCodeLens.foreground': '#999999',
        'editorCursor.foreground': '#000000',
        'editorError.foreground': '#d60a0a',
        'editorGroup.border': '#e7e7e7',
        'editorGroup.dropBackground': '#3399ff2e',
        'editorGroupHeader.noTabsBackground': '#ffffff',
        'editorGroupHeader.tabsBackground': '#f3f3f3',
        'editorGutter.addedBackground': '#81b88b',
        'editorGutter.background': '#ffffff',
        'editorGutter.deletedBackground': '#ca4b51',
        'editorGutter.modifiedBackground': '#66afe0',
        'editorHint.foreground': '#6c6c6c',
        'editorHoverWidget.background': '#efeff2',
        'editorHoverWidget.border': '#c8c8c8',
        'editorInfo.foreground': '#008000',
        'editorLineNumber.activeForeground': '#0b216f',
        'editorLineNumber.foreground': '#2b91af',
        'editorLink.activeForeground': '#0000ff',
        'editorMarkerNavigation.background': '#ffffff',
        'editorMarkerNavigationError.background': '#d60a0a',
        'editorMarkerNavigationInfo.background': '#008000',
        'editorMarkerNavigationWarning.background': '#117711',
        'editorOverviewRuler.addedForeground': '#007acc99',
        'editorOverviewRuler.border': '#7f7f7f4d',
        'editorOverviewRuler.bracketMatchForeground': '#a0a0a0',
        'editorOverviewRuler.commonContentForeground': '#60606066',
        'editorOverviewRuler.currentContentForeground': '#40c8ae80',
        'editorOverviewRuler.deletedForeground': '#007acc99',
        'editorOverviewRuler.errorForeground': '#ff1212b3',
        'editorOverviewRuler.findMatchForeground': '#f6b94db3',
        'editorOverviewRuler.incomingContentForeground': '#40a6ff80',
        'editorOverviewRuler.infoForeground': '#121288b3',
        'editorOverviewRuler.modifiedForeground': '#007acc99',
        'editorOverviewRuler.rangeHighlightForeground': '#007acc99',
        'editorOverviewRuler.selectionHighlightForeground': '#a0a0a0cc',
        'editorOverviewRuler.warningForeground': '#128812b3',
        'editorOverviewRuler.wordHighlightForeground': '#a0a0a0cc',
        'editorOverviewRuler.wordHighlightStrongForeground': '#c0a0c0cc',
        'editorPane.background': '#ffffff',
        'editorRuler.foreground': '#d3d3d3',
        'editorSuggestWidget.border': '#c8c8c8',
        'editorSuggestWidget.foreground': '#000000',
        'editorSuggestWidget.highlightForeground': '#007acc',
        'editorSuggestWidget.selectedBackground': '#dcebfc',
        'editorUnnecessaryCode.opacity': '#00000077',
        'editorWarning.foreground': '#117711',
        'editorWhitespace.foreground': '#33333333',
        'editorWidget.background': '#efeff2',
        'editorWidget.border': '#c8c8c8',
        errorForeground: '#a1260d',
        'extensionButton.prominentBackground': '#327e36',
        'extensionButton.prominentForeground': '#ffffff',
        'extensionButton.prominentHoverBackground': '#28632b',
        focusBorder: '#007acc66',
        foreground: '#6c6c6c',
        'gitDecoration.addedResourceForeground': '#587c0c',
        'gitDecoration.conflictingResourceForeground': '#6c6cc4',
        'gitDecoration.deletedResourceForeground': '#ad0707',
        'gitDecoration.ignoredResourceForeground': '#8e8e90',
        'gitDecoration.modifiedResourceForeground': '#a76e12',
        'gitDecoration.submoduleResourceForeground': '#1258a7',
        'gitDecoration.untrackedResourceForeground': '#019001',
        'input.background': '#ffffff',
        'input.foreground': '#6c6c6c',
        'inputOption.activeBorder': '#007acc',
        'inputValidation.errorBackground': '#f2dede',
        'inputValidation.errorBorder': '#be1100',
        'inputValidation.infoBackground': '#d6ecf2',
        'inputValidation.infoBorder': '#007acc',
        'inputValidation.warningBackground': '#f6f5d2',
        'inputValidation.warningBorder': '#b89500',
        'list.activeSelectionBackground': '#3399ff',
        'list.activeSelectionForeground': '#ffffff',
        'list.dropBackground': '#dcebfc',
        'list.errorForeground': '#d60a0a',
        'list.focusBackground': '#dcebfc',
        'list.highlightForeground': '#007acc',
        'list.inactiveFocusBackground': '#d8dae6',
        'list.inactiveSelectionBackground': '#cccedb',
        'list.invalidItemForeground': '#b89500',
        'list.warningForeground': '#117711',
        'merge.commonContentBackground': '#60606029',
        'merge.commonHeaderBackground': '#60606066',
        'merge.currentContentBackground': '#40c8ae33',
        'merge.currentHeaderBackground': '#40c8ae80',
        'merge.incomingContentBackground': '#40a6ff33',
        'merge.incomingHeaderBackground': '#40a6ff80',
        'notificationCenterHeader.background': '#e2e2e7',
        'notificationLink.foreground': '#4080d0',
        'notifications.background': '#efeff2',
        'notifications.border': '#e2e2e7',
        'panel.background': '#ffffff',
        'panel.border': '#80808059',
        'panel.dropBackground': '#3399ff2e',
        'panelTitle.activeBorder': '#80808059',
        'panelTitle.activeForeground': '#424242',
        'panelTitle.inactiveForeground': '#424242bf',
        'peekView.border': '#007acc',
        'peekViewEditor.background': '#f2f8fc',
        'peekViewEditor.matchHighlightBackground': '#f5d802de',
        'peekViewEditorGutter.background': '#f2f8fc',
        'peekViewResult.background': '#f3f3f3',
        'peekViewResult.fileForeground': '#1e1e1e',
        'peekViewResult.lineForeground': '#646465',
        'peekViewResult.matchHighlightBackground': '#ea5c004d',
        'peekViewResult.selectionBackground': '#3399ff33',
        'peekViewResult.selectionForeground': '#6c6c6c',
        'peekViewTitle.background': '#ffffff',
        'peekViewTitleDescription.foreground': '#6c6c6cb3',
        'peekViewTitleLabel.foreground': '#333333',
        'pickerGroup.border': '#cccedb',
        'pickerGroup.foreground': '#007acc99',
        'progressBar.background': '#0e70c0',
        'scrollbar.shadow': '#dddddd',
        'scrollbarSlider.activeBackground': '#00000099',
        'scrollbarSlider.background': '#64646466',
        'scrollbarSlider.hoverBackground': '#646464b3',
        'settings.modifiedItemForeground': '#019001',
        'sideBar.background': '#f3f3f3',
        'sideBar.dropBackground': '#ffffff1f',
        'sideBarSectionHeader.background': '#80808033',
        'statusBar.background': '#007acc',
        'statusBar.debuggingBackground': '#cc6633',
        'statusBar.debuggingForeground': '#ffffff',
        'statusBar.foreground': '#ffffff',
        'statusBar.noFolderBackground': '#68217a',
        'statusBar.noFolderForeground': '#ffffff',
        'statusBarItem.activeBackground': '#ffffff2e',
        'statusBarItem.hoverBackground': '#ffffff1f',
        'statusBarItem.prominentBackground': '#388a34',
        'statusBarItem.prominentHoverBackground': '#369432',
        'tab.activeBackground': '#ffffff',
        'tab.activeForeground': '#333333',
        'tab.border': '#f3f3f3',
        'tab.inactiveBackground': '#ececec',
        'tab.inactiveForeground': '#33333380',
        'tab.unfocusedActiveForeground': '#333333b3',
        'tab.unfocusedInactiveForeground': '#33333340',
        'terminal.ansiBlack': '#000000',
        'terminal.ansiBlue': '#0451a5',
        'terminal.ansiBrightBlack': '#666666',
        'terminal.ansiBrightBlue': '#0451a5',
        'terminal.ansiBrightCyan': '#0598bc',
        'terminal.ansiBrightGreen': '#14ce14',
        'terminal.ansiBrightMagenta': '#bc05bc',
        'terminal.ansiBrightRed': '#cd3131',
        'terminal.ansiBrightWhite': '#a5a5a5',
        'terminal.ansiBrightYellow': '#b5ba00',
        'terminal.ansiCyan': '#0598bc',
        'terminal.ansiGreen': '#00bc00',
        'terminal.ansiMagenta': '#bc05bc',
        'terminal.ansiRed': '#cd3131',
        'terminal.ansiWhite': '#555555',
        'terminal.ansiYellow': '#949800',
        'terminal.foreground': '#333333',
        'terminal.selectionBackground': '#00000040',
        'textBlockQuote.background': '#7f7f7f1a',
        'textBlockQuote.border': '#007acc80',
        'textCodeBlock.background': '#dcdcdc66',
        'textLink.activeForeground': '#4080d0',
        'textLink.foreground': '#4080d0',
        'textPreformat.foreground': '#a31515',
        'textSeparator.foreground': '#0000002e',
        'titleBar.activeBackground': '#dddddd',
        'titleBar.activeForeground': '#333333',
        'titleBar.inactiveBackground': '#dddddd99',
        'titleBar.inactiveForeground': '#33333399',
        'widget.shadow': '#a8a8a8',
    },
    tokenColors: [
        {
            scope: ['meta.embedded', 'source.groovy.embedded'],
            settings: {
                foreground: '#000000ff',
            },
        },
        {
            scope: 'emphasis',
            settings: {
                fontStyle: 'italic',
            },
        },
        {
            scope: 'strong',
            settings: {
                fontStyle: 'bold',
            },
        },
        {
            scope: 'meta.diff.header',
            settings: {
                foreground: '#000080',
            },
        },
        {
            scope: 'comment',
            settings: {
                foreground: '#008000',
            },
        },
        {
            scope: 'constant.language',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: ['constant.numeric'],
            settings: {
                foreground: '#09885a',
            },
        },
        {
            scope: 'constant.regexp',
            settings: {
                foreground: '#811f3f',
            },
        },
        {
            name: 'css tags in selectors, xml tags',
            scope: 'entity.name.tag',
            settings: {
                foreground: '#800000',
            },
        },
        {
            scope: 'entity.name.selector',
            settings: {
                foreground: '#800000',
            },
        },
        {
            scope: 'entity.other.attribute-name',
            settings: {
                foreground: '#ff0000',
            },
        },
        {
            scope: [
                'entity.other.attribute-name.class.css',
                'entity.other.attribute-name.class.mixin.css',
                'entity.other.attribute-name.id.css',
                'entity.other.attribute-name.parent-selector.css',
                'entity.other.attribute-name.pseudo-class.css',
                'entity.other.attribute-name.pseudo-element.css',
                'source.css.less entity.other.attribute-name.id',
                'entity.other.attribute-name.attribute.scss',
                'entity.other.attribute-name.scss',
            ],
            settings: {
                foreground: '#800000',
            },
        },
        {
            scope: 'invalid',
            settings: {
                foreground: '#cd3131',
            },
        },
        {
            scope: 'markup.underline',
            settings: {
                fontStyle: 'underline',
            },
        },
        {
            scope: 'markup.bold',
            settings: {
                fontStyle: 'bold',
                foreground: '#000080',
            },
        },
        {
            scope: 'markup.heading',
            settings: {
                fontStyle: 'bold',
                foreground: '#800000',
            },
        },
        {
            scope: 'markup.italic',
            settings: {
                fontStyle: 'italic',
            },
        },
        {
            scope: 'markup.inserted',
            settings: {
                foreground: '#09885a',
            },
        },
        {
            scope: 'markup.deleted',
            settings: {
                foreground: '#a31515',
            },
        },
        {
            scope: 'markup.changed',
            settings: {
                foreground: '#0451a5',
            },
        },
        {
            scope: [
                'beginning.punctuation.definition.quote.markdown',
                'beginning.punctuation.definition.list.markdown',
            ],
            settings: {
                foreground: '#0451a5',
            },
        },
        {
            scope: 'markup.inline.raw',
            settings: {
                foreground: '#800000',
            },
        },
        {
            scope: 'meta.selector',
            settings: {
                foreground: '#800000',
            },
        },
        {
            name: 'brackets of XML/HTML tags',
            scope: 'punctuation.definition.tag',
            settings: {
                foreground: '#800000',
            },
        },
        {
            scope: 'meta.preprocessor',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'meta.preprocessor.string',
            settings: {
                foreground: '#a31515',
            },
        },
        {
            scope: 'meta.preprocessor.numeric',
            settings: {
                foreground: '#09885a',
            },
        },
        {
            scope: 'meta.structure.dictionary.key.python',
            settings: {
                foreground: '#0451a5',
            },
        },
        {
            scope: 'storage',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'storage.type',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'storage.modifier',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'string',
            settings: {
                foreground: '#a31515',
            },
        },
        {
            scope: [
                'string.comment.buffered.block.pug',
                'string.quoted.pug',
                'string.interpolated.pug',
                'string.unquoted.plain.in.yaml',
                'string.unquoted.plain.out.yaml',
                'string.unquoted.block.yaml',
                'string.quoted.single.yaml',
                'string.quoted.double.xml',
                'string.quoted.single.xml',
                'string.unquoted.cdata.xml',
                'string.quoted.double.html',
                'string.quoted.single.html',
                'string.unquoted.html',
                'string.quoted.single.handlebars',
                'string.quoted.double.handlebars',
            ],
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'string.regexp',
            settings: {
                foreground: '#811f3f',
            },
        },
        {
            name: 'String interpolation',
            scope: [
                'punctuation.definition.template-expression.begin',
                'punctuation.definition.template-expression.end',
                'punctuation.section.embedded',
            ],
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            name: 'Reset JavaScript string interpolation expression',
            scope: ['meta.template.expression'],
            settings: {
                foreground: '#000000',
            },
        },
        {
            scope: [
                'support.constant.property-value',
                'support.constant.font-name',
                'support.constant.media-type',
                'support.constant.media',
                'constant.other.color.rgb-value',
                'constant.other.rgb-value',
                'support.constant.color',
            ],
            settings: {
                foreground: '#0451a5',
            },
        },
        {
            scope: [
                'support.type.vendored.property-name',
                'support.type.property-name',
                'variable.css',
                'variable.scss',
                'variable.other.less',
                'source.coffee.embedded',
            ],
            settings: {
                foreground: '#ff0000',
            },
        },
        {
            scope: ['support.type.property-name.json'],
            settings: {
                foreground: '#0451a5',
            },
        },
        {
            scope: 'keyword',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'keyword.control',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'keyword.operator',
            settings: {
                foreground: '#000000',
            },
        },
        {
            scope: [
                'keyword.operator.new',
                'keyword.operator.expression',
                'keyword.operator.cast',
                'keyword.operator.sizeof',
                'keyword.operator.instanceof',
                'keyword.operator.logical.python',
            ],
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'keyword.other.unit',
            settings: {
                foreground: '#09885a',
            },
        },
        {
            scope: [
                'punctuation.section.embedded.begin.php',
                'punctuation.section.embedded.end.php',
            ],
            settings: {
                foreground: '#800000',
            },
        },
        {
            scope: 'support.function.git-rebase',
            settings: {
                foreground: '#0451a5',
            },
        },
        {
            scope: 'constant.sha.git-rebase',
            settings: {
                foreground: '#09885a',
            },
        },
        {
            name: 'coloring of the Java import and package identifiers',
            scope: [
                'storage.modifier.import.java',
                'variable.language.wildcard.java',
                'storage.modifier.package.java',
            ],
            settings: {
                foreground: '#000000',
            },
        },
        {
            name: 'this.self',
            scope: 'variable.language',
            settings: {
                foreground: '#0000ff',
            },
        },
        {
            scope: 'token.info-token',
            settings: {
                foreground: '#316bcd',
            },
        },
        {
            scope: 'token.warn-token',
            settings: {
                foreground: '#cd9731',
            },
        },
        {
            scope: 'token.error-token',
            settings: {
                foreground: '#cd3131',
            },
        },
        {
            scope: 'token.debug-token',
            settings: {
                foreground: '#800080',
            },
        },
    ],
};
