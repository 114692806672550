"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colorsV2 = exports.colors = void 0;
exports.colors = {
    white: '#ffffff',
    grays: {
        100: '#fff9f9',
        200: '#e6e6e6',
        300: '#999999',
        400: '#757575',
        500: '#343434',
        600: '#242424',
        700: '#191919',
        800: '#111111',
        900: '#040404',
    },
    blues: {
        300: '#6CC7F6',
        500: '#3793E0',
        600: '#0971f1',
        700: '#535BCF',
        900: '#021D3E',
    },
    reds: {
        200: '#EB455A',
        300: '#FF453A',
        500: '#E1270E', // Red
    },
    green: '#5BC266',
    purple: '#AC9CFF',
    purpleOpaque: '#AC9CFF60',
    yellow: '#FBCC43',
    orange: '#f69935',
};
exports.colorsV2 = {
    yellowish: {
        800: '#D6FB41',
        700: '#DCFF50',
        600: '#E3FF73',
        500: '#EAFF96',
        400: '#F1FFB9',
        300: '#F5FFCB',
        200: '#F8FFDC',
        100: '#FCFFEE',
    },
    purple: {
        800: '#644ED7',
        700: '#7B61FF',
        600: '#9581FF',
        500: '#B0A0FF',
        400: '#CAC0FF',
        300: '#D7D0FF',
        200: '#E5DFFF',
        100: '#F2EFFF',
    },
    red: {
        800: '#EB5E5E',
        700: '#ED6C6C',
        600: '#EF7A7A',
        500: '#F08787',
        400: '#F29595',
        300: '#F39C9C',
        200: '#F3A2A2',
        100: '#F5B0B0',
    },
    black: {
        800: '#0E0E0E',
        700: '#161616',
        600: '#1D1D1D',
        500: '#252525',
        400: '#292929',
        300: '#2D2D2D',
        200: '#303030',
        100: '#343434',
    },
    gray: {
        800: '#999999',
        700: '#ADADAD',
        600: '#C2C2C2',
        500: '#D6D6D6',
        400: '#E0E0E0',
        300: '#EBEBEB',
        200: '#F5F5F5',
        100: '#FFFFFF',
    },
    green: {
        800: '#95F998',
        700: '#A4FAA6',
        600: '#B3FBB4',
        500: '#C1FCC2',
        400: '#D0FCD1',
        300: '#D7FDD8',
        200: '#DEFDDF',
        100: '#EDFEED',
    },
    orange: {
        800: '#EC775B',
        700: '#EE8269',
        600: '#F08D77',
        500: '#F19985',
        400: '#F3A492',
        300: '#F4AA99',
        200: '#F4B0A0',
        100: '#F6BBAE',
    },
    blue: {
        800: '#5F8BF7',
        700: '#6E96F8',
        600: '#7DA1F9',
        500: '#8BABFA',
        400: '#9AB6FA',
        300: '#A1BBFB',
        200: '#A8C0FB',
        100: '#B7CBFC',
    },
    yellow: {
        800: '#D0AA52',
        700: '#F7CC66',
        600: '#F9D685',
        500: '#FAE0A3',
        400: '#FCEBC2',
        300: '#FDF0D1',
        200: '#FDF5E0',
        100: '#FFFFFF',
    },
};
