"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontWeights = exports.fontSizes = void 0;
exports.fontSizes = {
    0: 0,
    1: 9,
    2: 12,
    3: 13,
    4: 16,
    5: 19,
    6: 24,
    7: 28,
    8: 33,
    9: 40,
};
exports.fontWeights = {
    // matches Inter weights
    thin: 100,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
};
