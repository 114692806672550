"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Emitter = void 0;
class Emitter {
    constructor() {
        this.listeners = new Set();
        this.event = (cb) => {
            this.listeners.add(cb);
            return () => {
                this.listeners.delete(cb);
            };
        };
    }
    emit(message) {
        this.listeners.forEach(listener => {
            listener(message);
        });
    }
}
exports.Emitter = Emitter;
